// Tekst i de kategoriene som får en popup før man melder inn en sak

const kategoribeskrivelser = {
  no: {
    // Trafikklys - Tidsintervall
    'kategoribeskrivelse.header-a7e76356-54cf-454c-84fb-68a40e6869bd': 'Tidsintervall',
    'kategoribeskrivelse.sub-a7e76356-54cf-454c-84fb-68a40e6869bd': 'Trafikklys',
    'kategoribeskrivelse.body-a7e76356-54cf-454c-84fb-68a40e6869bd': `Kollektivtrafikken blir prioritert gjennom signalanleggene. Det medfører at vi avkorter eller forlenger noen tidsintervaller, og det vil enkelte ganger være lengre ventetid før det blir grønt for andre trafikanter. Uansett vil det være slik at sikkerhetstider og minimumstider alltid overholdes.\n\nEr det likevel annet som gjelder tider du mener bør justeres, så meld det gjerne inn her. Vi vil vurdere om det er noe feil, og om det er noe vi kan gjøre noe med. En eventuell retting vil gjøres i løpet av noen uker, avhengig av arbeidsmengde.`,

    // Trafikklys - Mørkt anlegg
    'kategoribeskrivelse.header-740fead8-61ee-473f-8b8f-683b92a73ba1': 'Mørkt anlegg',
    'kategoribeskrivelse.sub-740fead8-61ee-473f-8b8f-683b92a73ba1': 'Trafikklys',
    'kategoribeskrivelse.body-740fead8-61ee-473f-8b8f-683b92a73ba1': `Vi har to timers responstid fra vi oppdager at et anlegg er ute av drift til entreprenøren skal være i gang med feilretting. Det innebærer at det kan gå to timer fra vi mottar melding til vi er i gang med feilretting.\n\nDersom det er større feil (f.eks jordfeil på kabler som medfører graving eller annet tidkrevende arbeid), så rettes dette så raskt som mulig.`,

    // Trafikklys - Blinker gult
    'kategoribeskrivelse.header-51cb10f3-f6de-4d85-8547-bfaffc793274': 'Blinker gult',
    'kategoribeskrivelse.sub-51cb10f3-f6de-4d85-8547-bfaffc793274': 'Trafikklys',
    'kategoribeskrivelse.body-51cb10f3-f6de-4d85-8547-bfaffc793274': `Vi har to timers responstid fra vi oppdager at et anlegg er ute av drift/gulblink til entreprenøren skal være i gang med feilretting. Det innebærer at det kan gå to timer fra vi mottar melding til vi er i gang med feilretting. \n\nDersom det er større feil (f.eks. jordfeil på kabler som medfører graving eller annet tidkrevende arbeid), så rettes det så raskt som mulig.`,

    // Trafikklys - Deteksjon/sensorfeil
    'kategoribeskrivelse.header-da863d61-4cf1-42ba-a192-2c4e27f5a053': 'Deteksjon/sensorfeil',
    'kategoribeskrivelse.sub-da863d61-4cf1-42ba-a192-2c4e27f5a053': 'Trafikklys',
    'kategoribeskrivelse.body-da863d61-4cf1-42ba-a192-2c4e27f5a053': `Det er flere typer sensorer i signalanleggene, som automatisk tolking av videobilder, radar og infrarøde sensorer. Det vanligste er en ledning som ligger i en sløyfe under asfalten. Når det kommer en bil med metall i karosseriet, lager den en elektrisk impuls i ledningen. Der sensorene fanger opp et kjøretøy som kommer fra en sidevei, benyttes som regel video eller sløyfe. Når det er dårlige lysforhold, hender det at signalanlegget ikke registrerer motorsykler og syklister. Sykler består av relativt lite metall, slik at en sløyfe i bakken ikke alltid klarer å registrere den. Det kan hjelpe å stoppe foran stopplinja. Om du krysser denne, kan det være signalet ditt blir «slettet».\n\nDersom du opplever at signalanlegget ikke registrerer deg, så er det fint med tilbakemelding om tidspunkt og værforhold når det skjedde, og hvilken retning du kom fra og skulle til.`,

    // Trafikklys - Deteksjon/sensorfeil
    'kategoribeskrivelse.header-b4652c1e-d3b7-4e6f-905b-3341da24f80f': 'Annet',
    'kategoribeskrivelse.sub-b4652c1e-d3b7-4e6f-905b-3341da24f80f': 'Trafikklys',
    'kategoribeskrivelse.body-b4652c1e-d3b7-4e6f-905b-3341da24f80f': `Her kan du melde inn andre ting som gjelder trafikklyset. Det kan være ting du mener skal endres, eller dersom det er skader på utstyr o.l.\n\nØnske om nytt signalanlegg/trafikklys skal ikke meldes her. Det må sendes inn som egen sak til postmottak@bym.oslo.kommune.no.\n\nDet er mange kriterier som skal oppfylles for at et kryss kan signalreguleres, og selv om de er oppfylt, er det ikke sikkert det blir signalregulert. Det kan være andre tiltak som fungerer bedre.`,

    // Mørkt område minst 4 stk
    'kategoribeskrivelse.header-710da443-87f6-4b0b-aaf1-d4616fc637d5': 'Mørkt område minst 4 stk',
    'kategoribeskrivelse.sub-710da443-87f6-4b0b-aaf1-d4616fc637d5': 'Gatelys',
    'kategoribeskrivelse.body-710da443-87f6-4b0b-aaf1-d4616fc637d5':
      'Er det feil på 4 lamper eller flere, marker området i kartet og meld inn mørkt område.\n\nNår et større område er mørkt er årsaken ofte kabelbrudd eller feil på strømforsyningen, og utbedring kan ta noe lenger tid enn ved en enkelt mørk lampe.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet.\n\nI hovedsak vil utbedring være påbegynt etter 1 virkedag.',

    // Mørk lampe
    'kategoribeskrivelse.header-6e6078e5-f479-4800-90d3-08b465b95885': 'Mørk lampe',
    'kategoribeskrivelse.img-alt-6e6078e5-f479-4800-90d3-08b465b95885': 'Mørk lampe',
    'kategoribeskrivelse.sub-6e6078e5-f479-4800-90d3-08b465b95885': 'Gatelys',
    'kategoribeskrivelse.body-6e6078e5-f479-4800-90d3-08b465b95885':
      'Meld fra om mørke lamper.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Annet
    'kategoribeskrivelse.header-03bddaed-51ff-4df8-a10e-8a9111390c29': 'Gatelys',
    'kategoribeskrivelse.sub-03bddaed-51ff-4df8-a10e-8a9111390c29': 'Annet',
    'kategoribeskrivelse.body-03bddaed-51ff-4df8-a10e-8a9111390c29':
      'Meld fra om andre driftsmessige forhold på gatebelysningen.\n\nSpørsmål om nyanlegg eller utvidelser av gatelysanleggene besvares raskest ved henvendelse til: <email>postmottak@bym.oslo.kommune.no</email>.',

    // Gatelys - Blender
    'kategoribeskrivelse.header-aa852bba-c61e-4208-83c0-d7be4575a93f': 'Gatelys',
    'kategoribeskrivelse.sub-aa852bba-c61e-4208-83c0-d7be4575a93f': 'Blender',
    'kategoribeskrivelse.body-aa852bba-c61e-4208-83c0-d7be4575a93f':
      'Bymiljøetaten har i de siste årene byttet ut mange armaturer til LED. Disse kan virke noe mer blendene enn de gamle tradisjonelle armaturene. Vi legger vekt på å oppnå en best mulig lysfordeling ved bytte av armaturer eller ved bygging av nye anlegg. Dette for å unngå blending og unødig lysforurensning.\n\nOm et av Bymiljøetatens gatelysarmaturer sender sjenerende lys i retninger den ikke skal, kan vi bytte ut armatur med annen lysfordeling eller skjerme av armaturet.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret. ',

    // Gatelys - Blunker
    'kategoribeskrivelse.header-dbde242f-8bad-42ac-b332-611b1672628b': 'Gatelys',
    'kategoribeskrivelse.sub-dbde242f-8bad-42ac-b332-611b1672628b': 'Blunker',
    'kategoribeskrivelse.body-dbde242f-8bad-42ac-b332-611b1672628b':
      'At et armatur i løpet av natten slukker for så å tenne opp igjen etter noen minutter kan tyde på at lyskilden er i ferd med gå ut på levetid. Nye LED armaturer kan også ved feil begynne å blunke.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Greiner skygger for belysning
    'kategoribeskrivelse.header-ad5977d9-8f01-4120-b363-c43b4c8a56a4': 'Gatelys',
    'kategoribeskrivelse.sub-ad5977d9-8f01-4120-b363-c43b4c8a56a4': 'Greiner skygger for belysning',
    'kategoribeskrivelse.body-ad5977d9-8f01-4120-b363-c43b4c8a56a4':
      'Fra tid til annen kan vegetasjon rundt armaturer føre til skygger som gjør sikten i gaten dårlig på kveld- og nattestid.\n\nVi gjør oppmerksom på at tomteeier selv er ansvarlig for trær på egen eiendom og Bymiljøetaten kun vil utføre nødvendig beskjæring for å sikre belysning av gaten.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Lyser på dagen
    'kategoribeskrivelse.header-314aee7b-e59c-4f95-a313-8cf7faa35fcc': 'Gatelys',
    'kategoribeskrivelse.sub-314aee7b-e59c-4f95-a313-8cf7faa35fcc': 'Lyser på dagen',
    'kategoribeskrivelse.body-314aee7b-e59c-4f95-a313-8cf7faa35fcc':
      'I noen tilfeller vi vår driftspartner tenne opp anlegg på dagtid for funksjonskontroll og feilretting.\n\nOm anlegg blir stående på hele dagen, eller flere dager på rad kan det tyde på feil ved styresystemet.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Skadet armatur/lampe:
    'kategoribeskrivelse.header-9c780e3e-e65c-41f8-837e-facbb47bfc55': 'Gatelys',
    'kategoribeskrivelse.sub-9c780e3e-e65c-41f8-837e-facbb47bfc55': 'Skadet armatur/lampe',
    'kategoribeskrivelse.body-9c780e3e-e65c-41f8-837e-facbb47bfc55':
      'Armaturer som ikke er ordentlig festet eller ute av stilling kan fort utgjøre en fare for forbipasserende.\n\nVi vil gjerne ha beskjed så snart som mulig når slike forhold oppdages. Legg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Skadet ledning:
    'kategoribeskrivelse.header-f98ef9f5-7e04-40d9-b50c-c20612652e27': 'Gatelys',
    'kategoribeskrivelse.sub-f98ef9f5-7e04-40d9-b50c-c20612652e27': 'Skadet ledning',
    'kategoribeskrivelse.body-f98ef9f5-7e04-40d9-b50c-c20612652e27':
      'Ledninger eller kabler som ikke er ordentlig festet eller ute av stilling kan fort utgjøre en fare for forbipasserende.\n\nVi vil gjerne ha beskjed så snart som mulig når slike forhold oppdages. Legg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Gatelys - Skadet mast/stolpe:
    'kategoribeskrivelse.header-2521a526-9cef-408f-b02c-797e1a618434': 'Gatelys',
    'kategoribeskrivelse.sub-2521a526-9cef-408f-b02c-797e1a618434': 'Skadet mast/stolpe',
    'kategoribeskrivelse.body-2521a526-9cef-408f-b02c-797e1a618434':
      'Master eller stolper som ikke er ordentlig festet eller ute av stilling kan fort utgjøre en fare for forbipasserende.\n\nVi vil gjerne ha beskjed så snart som mulig når slike forhold oppdages. Legg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.\n\nGatelys som Bymiljøetaten er ansvarlig for er plottet inn i kartet. Merk at det kan ta opptil 15 virkedager å få feilen utbedret.',

    // Renhold - Fortau
    'kategoribeskrivelse.header-b9bbb1ca-0914-4f4c-ae8b-b62ae2529a08': 'Fortau',
    'kategoribeskrivelse.sub-b9bbb1ca-0914-4f4c-ae8b-b62ae2529a08': 'Renhold',
    'kategoribeskrivelse.body-b9bbb1ca-0914-4f4c-ae8b-b62ae2529a08':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.',
    'kategoribeskrivelse.time-sensitive-body-b9bbb1ca-0914-4f4c-ae8b-b62ae2529a08':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>',

    // Renhold - Holdeplass
    'kategoribeskrivelse.header-8e20a409-166b-44da-9c08-737ff2d35c4e': 'Holdeplass',
    'kategoribeskrivelse.sub-8e20a409-166b-44da-9c08-737ff2d35c4e': 'Renhold',
    'kategoribeskrivelse.body-8e20a409-166b-44da-9c08-737ff2d35c4e':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.',
    'kategoribeskrivelse.time-sensitive-body-8e20a409-166b-44da-9c08-737ff2d35c4e':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>',

    // Renhold - P-plass
    'kategoribeskrivelse.header-b598b3e2-95af-49f8-a122-cf29d3add2af': 'P-plass',
    'kategoribeskrivelse.sub-b598b3e2-95af-49f8-a122-cf29d3add2af': 'Renhold',
    'kategoribeskrivelse.body-b598b3e2-95af-49f8-a122-cf29d3add2af':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.',
    'kategoribeskrivelse.time-sensitive-body-b598b3e2-95af-49f8-a122-cf29d3add2af':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>',

    // Renhold - Sykkelfelt
    'kategoribeskrivelse.header-383235cc-9c77-474f-bd40-c3e178af111c': 'Sykkelfelt',
    'kategoribeskrivelse.sub-383235cc-9c77-474f-bd40-c3e178af111c': 'Renhold',
    'kategoribeskrivelse.body-383235cc-9c77-474f-bd40-c3e178af111c':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.\n\nLegg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',
    'kategoribeskrivelse.time-sensitive-body-383235cc-9c77-474f-bd40-c3e178af111c':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>\n\nLegg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',

    // Renhold - Tur-, gang-, og sykkelvei
    'kategoribeskrivelse.header-02db6222-0743-4368-8539-09f82534bdb4': 'Tur-, gang-, og sykkelvei',
    'kategoribeskrivelse.sub-02db6222-0743-4368-8539-09f82534bdb4': 'Renhold',
    'kategoribeskrivelse.body-02db6222-0743-4368-8539-09f82534bdb4':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.\n\nLegg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',
    'kategoribeskrivelse.time-sensitive-body-02db6222-0743-4368-8539-09f82534bdb4':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>\n\nLegg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',

    // Renhold - Veibane
    'kategoribeskrivelse.header-7feefdf7-2970-4ba1-bdff-62062dd5cd62': 'Veibane',
    'kategoribeskrivelse.sub-7feefdf7-2970-4ba1-bdff-62062dd5cd62': 'Renhold',
    'kategoribeskrivelse.body-7feefdf7-2970-4ba1-bdff-62062dd5cd62':
      'Etaten foretar et omfattende daglig renhold.\nAlle gater innenfor Ring 1 rengjøres hver eneste natt hele året. Områdene rundt Karl Johans gate spyles hver natt om værforholdene tillater vannbruk.\nDet foretas renhold av hovedveier annen hver uke så lenge forholdene på veien tillater feiing. Det prioriterte sykkelveinettet blir feid annen hver uke.\nDet foretas også vanligvis løvfeiing av utvalgte gater om høsten.\nAv generelt renhold og vask av alle byens gater så gjennomføres ikke dette mer enn én gang i året i forbindelse med vårrengjøringen i Oslo og ellers noe sporadisk søppelplukking.\nI henhold til politivedtektene for Oslo by er gårdeier ansvarlig for å holde fortau tilstøtende sin eiendom rent og fremkommelig i Indre by.\nNår det gjelder tømming av etatens avfallsbeholdere er det ulike tømmefrekvenser avhengig av plassering og årstid. Frekvensen varierer fra 2 ganger i døgnet til to ganger i uken.',

    'kategoribeskrivelse.time-sensitive-body-7feefdf7-2970-4ba1-bdff-62062dd5cd62':
      'Bymiljøetaten utfører årlig vårrengjøring av Oslos gater. Vi rengjør da alle kommunale gater, fortau, gang- og sykkelveier, med oppstart i perioden rundt påske og med mål om å bli ferdig til 17. mai. Oppstart og fremdrift er vær- og temperaturavhengig. Hvis en gate/strekning ikke blir rengjort i henhold til planlagt dato, vil den bli satt opp på nytt på et senere tidspunkt. Sjekk når det er planlagt vårrengjøring i din gate: <varrengjoring>https://www.oslo.kommune.no/gate-transport-og-parkering/veiarbeid-og-vedlikehold/varrengjoring/</varrengjoring>',

    // Problematiske fremmede arter - Park-, hybrid-, og kjempeslirekne
    'kategoribeskrivelse.header-4f1e96b3-3b7a-418e-b233-9acdd7912ad0': 'Park-, hybrid-, og kjempeslirekne',
    'kategoribeskrivelse.img-alt-4f1e96b3-3b7a-418e-b233-9acdd7912ad0': 'Park-, hybrid-, og kjempeslirekne',
    'kategoribeskrivelse.sub-4f1e96b3-3b7a-418e-b233-9acdd7912ad0': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-4f1e96b3-3b7a-418e-b233-9acdd7912ad0':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Problematiske fremmede arter - Fagerfredløs
    'kategoribeskrivelse.header-d75297cd-cb5f-4dd7-ab79-bf7b38b271f9': 'Fagerfredløs',
    'kategoribeskrivelse.img-alt-d75297cd-cb5f-4dd7-ab79-bf7b38b271f9': 'Fagerfredløs',
    'kategoribeskrivelse.sub-d75297cd-cb5f-4dd7-ab79-bf7b38b271f9': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-d75297cd-cb5f-4dd7-ab79-bf7b38b271f9':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Problematiske fremmede arter - Kjempebjørnekjeks / tromsøpalme
    'kategoribeskrivelse.header-3d46fcab-62a4-42ed-aff5-2ea2133f814d': 'Kjempebjørnekjeks / tromsøpalme',
    'kategoribeskrivelse.img-alt-3d46fcab-62a4-42ed-aff5-2ea2133f814d': 'Kjempebjørnekjeks / tromsøpalme',
    'kategoribeskrivelse.sub-3d46fcab-62a4-42ed-aff5-2ea2133f814d': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-3d46fcab-62a4-42ed-aff5-2ea2133f814d':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Problematiske fremmede arter - Kjempespringfrø / mongolspringfrø
    'kategoribeskrivelse.header-773039f8-058c-4272-8c33-ed5e873c1b42': 'Kjempespringfrø / mongolspringfrø',
    'kategoribeskrivelse.img-alt-773039f8-058c-4272-8c33-ed5e873c1b42': 'Kjempespringfrø / mongolspringfrø',
    'kategoribeskrivelse.sub-773039f8-058c-4272-8c33-ed5e873c1b42': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-773039f8-058c-4272-8c33-ed5e873c1b42':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Problematiske fremmede arter - Legepestrot / japanpestrot
    'kategoribeskrivelse.header-4dc6244c-a333-4bac-811c-d7e7d0f35e67': 'Legepestrot / japanpestrot',
    'kategoribeskrivelse.img-alt-4dc6244c-a333-4bac-811c-d7e7d0f35e67': 'Legepestrot / japanpestrot',
    'kategoribeskrivelse.sub-4dc6244c-a333-4bac-811c-d7e7d0f35e67': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-4dc6244c-a333-4bac-811c-d7e7d0f35e67':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Problematiske fremmede arter - Russesvalerot
    'kategoribeskrivelse.header-52c35fbd-4a1a-421e-835e-b9977ec5c818': 'Russesvalerot',
    'kategoribeskrivelse.img-alt-52c35fbd-4a1a-421e-835e-b9977ec5c818': 'Russesvalerot',
    'kategoribeskrivelse.sub-52c35fbd-4a1a-421e-835e-b9977ec5c818': 'Problematiske fremmede arter',
    'kategoribeskrivelse.body-52c35fbd-4a1a-421e-835e-b9977ec5c818':
      'For å best mulig kunne kartlegge forekomster av problematiske, fremmede arter i Oslo, ber vi om at du markerer forekomstens posisjon så nøyaktig som mulig i kartet, og at du legger ved bilder. <fremmedearter>Les mer om fremmede arter og kommunens arbeid med dem her</fremmedearter>.',

    // Søppel - Farlig avfall
    'kategoribeskrivelse.header-fe236f71-03f4-4d07-8607-3f8391c1adbf': 'Farlig avfall',
    'kategoribeskrivelse.img-alt-fe236f71-03f4-4d07-8607-3f8391c1adbf': 'Farlig avfall',
    'kategoribeskrivelse.sub-fe236f71-03f4-4d07-8607-3f8391c1adbf': 'Søppel',
    'kategoribeskrivelse.body-fe236f71-03f4-4d07-8607-3f8391c1adbf':
      'Her skal det kun meldes om farlig avfall, som for eksempel bilbatteri, glass, sprøyter og maling.',

    // Ulovlig overnatting - Utmark
    'kategoribeskrivelse.header-7ab15bf8-2a50-48af-9f3a-a37dd325b489': 'Utmark',
    'kategoribeskrivelse.img-alt-7ab15bf8-2a50-48af-9f3a-a37dd325b489': 'Utmark',
    'kategoribeskrivelse.sub-7ab15bf8-2a50-48af-9f3a-a37dd325b489': 'Ulovlig overnatting',
    'kategoribeskrivelse.body-7ab15bf8-2a50-48af-9f3a-a37dd325b489':
      'Her kan du melde ifra om ulovlig overnatting. I utmark kan du overnatte/telte i inntil to døgn på samme sted uten grunneiers tillatelse, men ikke nærmere enn 150 meter fra bebyggelse. Telting er som hovedregel ikke tillatt i naturreservater. Telting er ikke tillatt ved opparbeidede badevannsområder, som for eksempel Sognsvann og Bogstadvannet, eller i åssidene ned mot vann, elver og bekker med drikkevannsrestriksjoner.',

    // Ulovlig overnatting - Byggesonen
    'kategoribeskrivelse.header-336c2912-251f-4fb5-83aa-6f012ba40e2b': 'Byggesonen',
    'kategoribeskrivelse.img-alt-336c2912-251f-4fb5-83aa-6f012ba40e2b': 'Byggesonen',
    'kategoribeskrivelse.sub-336c2912-251f-4fb5-83aa-6f012ba40e2b': 'Ulovlig overnatting',
    'kategoribeskrivelse.body-336c2912-251f-4fb5-83aa-6f012ba40e2b':
      'Det er ifølge Politivedtekt for Oslo kommune §2-1 forbudt med telting, camping, overnatting og lignende i offentlige parker, grøntarealer, friområder, på veier eller plasser i tettbebygd strøk uten særskilt tillatelse fra kommunal myndighet. (For øyene og marka er det egne regler).',

    // Tagging - Tagging
    'kategoribeskrivelse.header-321fb626-e4b6-4b6c-b1eb-d0921c0bd136': 'Tagging',
    'kategoribeskrivelse.sub-321fb626-e4b6-4b6c-b1eb-d0921c0bd136': ' ',
    'kategoribeskrivelse.body-321fb626-e4b6-4b6c-b1eb-d0921c0bd136':
      'Etaten fjerner kun tagging/graffiti på egne installasjoner og forvaltningsområder, samt på bygårder og vegger hvor sameier/gårdeiere/borettslag har en abonnementsavtale med Bymiljøetaten.\n\nAbonnementsordningen er et tilbud til gårdeiere, borettslag, boligsameier og andre som ønsker en fasade uten skjemmende tagging/graffiti. De som abonnerer på denne ordningen, sikres at all tagging/graffiti blir fjernet fra fasaden innen 48 timer, mot et fast vederlag. Dette gjøres ved at de selv kontakter etaten og melder om taggingen.\nFor mer informasjon om denne ordningen, ta kontakt med Stopp Tagging AS på e-post <taggefrimail>vidar@graffi.no</taggefrimail> eller telefon <taggefrimobil>93 40 92 40</taggefrimobil>.\n\nSameier/gårdeiere/borettslag som ikke benytter denne ordningen er selv ansvarlig for å fjerne tagging på sine bygårder og vegger. Dette gjelder også for andre kommunale virksomheter og deres forvaltningsområde.',

    // Sykkel - Ødelagt servicestasjon/pumpe
    'kategoribeskrivelse.header-c2969974-8fe5-49be-a846-93f2487143f5': 'Sykkel',
    'kategoribeskrivelse.img-alt-c2969974-8fe5-49be-a846-93f2487143f5': 'Sykkel',
    'kategoribeskrivelse.sub-c2969974-8fe5-49be-a846-93f2487143f5': 'Ødelagt servicestasjon/pumpe',
    'kategoribeskrivelse.body-c2969974-8fe5-49be-a846-93f2487143f5':
      'Meld fra om feil og mangler på sykkelservicestasjoner her.\nBeskjeden går direkte til de som utbedrer feilen og du vil få tilbakemelding når din melding er behandlet.\nBehandlingstiden kan variere ut ifra pågang og arbeidsmengde.\nSykkelservicestasjoner som Bymiljøetaten har ansvaret for er plottet inn på kartet.',

    // Sykkel - Ødelagt sykkelstativ
    'kategoribeskrivelse.header-bd338dae-0351-4aec-b043-1f79d3f6326a': 'Sykkel',
    'kategoribeskrivelse.img-alt-bd338dae-0351-4aec-b043-1f79d3f6326a': 'Sykkel',
    'kategoribeskrivelse.sub-bd338dae-0351-4aec-b043-1f79d3f6326a': 'Ødelagt sykkelstativ',
    'kategoribeskrivelse.body-bd338dae-0351-4aec-b043-1f79d3f6326a':
      'Tusen takk for at du melder fra om ødelagte sykkelstativ.\n\nNB! Gjelder kun sykkelstativ som er kommunale og driftes av Bymiljøetaten i Oslo kommune. Vi kan dessverre ikke videreformidle meldinger om sykkelstativ som driftes av andre aktører, dette gjelder også bysykkelstativ.\n\nLegg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av skaden',

    // Skilt - Manglende / skadet gatenavnskilt
    'kategoribeskrivelse.header-a560bd97-f3a1-4422-a6ab-f69a5db8cd74': 'Skilt',
    'kategoribeskrivelse.img-alt-a560bd97-f3a1-4422-a6ab-f69a5db8cd74': 'Skilt',
    'kategoribeskrivelse.sub-a560bd97-f3a1-4422-a6ab-f69a5db8cd74': 'Manglende/skadet gatenavnskilt',
    'kategoribeskrivelse.body-a560bd97-f3a1-4422-a6ab-f69a5db8cd74':
      'Vi har et begrenset budsjett til gatenavnskilt.\n\nBudsjettet skal dekke både nyskilting og reparasjoner av eksisterende gatenavnskilt. Dessverre er innmeldte behov større enn budsjettene. Vi bestiller skilt fortløpende så lenge budsjettet tillater dette. Vi kan således ikke angi tidsaspekt for utbedring.\n\nInnmeldte saker slettes ikke.',

    // Badeplass - Livbøye
    'kategoribeskrivelse.header-abab2b52-a200-44e4-9d78-1bf4851642e2': 'Badeplass',
    'kategoribeskrivelse.img-alt-abab2b52-a200-44e4-9d78-1bf4851642e2': 'Badeplass',
    'kategoribeskrivelse.sub-abab2b52-a200-44e4-9d78-1bf4851642e2': 'Livbøye',
    'kategoribeskrivelse.body-abab2b52-a200-44e4-9d78-1bf4851642e2':
      'Meld i fra til oss om du oppdager at det er feil på en av våre livbøyer. Ser du at det er en bøye som mangler vil vi også gjerne ha en tilbakemelding på det, slik at vi kan få fikset eller erstattet den ødelagte eller savnede bøya. Det er ikke alle bøyer som tilhører Oslo kommune, men om du melder inn avvik til oss, skal vi sørge for å viderebringe beskjed til relevant aktør.',

    // Ladestolper - Lader ikke / ute av drift
    'kategoribeskrivelse.header-e3818f25-0510-4a6e-9826-2588af842d7f': 'Ladestolper',
    'kategoribeskrivelse.img-alt-e3818f25-0510-4a6e-9826-2588af842d7f': 'Ladestolper',
    'kategoribeskrivelse.sub-e3818f25-0510-4a6e-9826-2588af842d7f': 'Lader ikke / ute av drift',
    'kategoribeskrivelse.body-e3818f25-0510-4a6e-9826-2588af842d7f':
      'Har du satt kontakten helt inn?\nPrøv å gjenta ladeprosessen.\nBruk appen Bil i Oslo for å lade.',

    // Ladestolper - Skade / Hærverk
    'kategoribeskrivelse.header-fb948410-b878-45c4-86f9-42ba0d44916e': 'Ladestolper',
    'kategoribeskrivelse.img-alt-fb948410-b878-45c4-86f9-42ba0d44916e': 'Ladestolper',
    'kategoribeskrivelse.sub-fb948410-b878-45c4-86f9-42ba0d44916e': 'Skade / hærverk',
    'kategoribeskrivelse.body-fb948410-b878-45c4-86f9-42ba0d44916e':
      'Om det er åpne elektriske kabler/koblinger, vennligst ring vaktsentralen umiddelbart på tlf. 23482100.',

    // Ladestolper - Lyser hvitt eller rødt
    'kategoribeskrivelse.header-8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb': 'Ladestolper',
    'kategoribeskrivelse.img-alt-8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb': 'Ladestolper',
    'kategoribeskrivelse.sub-8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb': 'Lyser hvitt eller rødt',
    'kategoribeskrivelse.body-8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb':
      'Har du satt kontakten helt inn?\nPrøv å gjenta ladeprosessen.\nBruk appen Bil i Oslo for å lade.',

    // Ladestolper - Ladekabel sitter fast
    'kategoribeskrivelse.header-ff8c7673-d2a8-4257-bfd8-0f2607b0a2da': 'Ladestolper',
    'kategoribeskrivelse.img-alt-ff8c7673-d2a8-4257-bfd8-0f2607b0a2da': 'Ladestolper',
    'kategoribeskrivelse.sub-ff8c7673-d2a8-4257-bfd8-0f2607b0a2da': 'Ladekabel sitter fast',
    'kategoribeskrivelse.body-ff8c7673-d2a8-4257-bfd8-0f2607b0a2da':
      '1. Koble ladekabel fra bilen.\n2. Trykk inn ladekabelen i ladestolpen.\n3. Vent opptil 15 minutter.\n4. Ring vaktsentralen på tlf: 23482100',

    // Betalingsautomater - Skade / hærverk
    'kategoribeskrivelse.header-bb54d45b-3534-4583-9ad3-4d24960a7a5a': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-bb54d45b-3534-4583-9ad3-4d24960a7a5a': 'Betalingsautomater',
    'kategoribeskrivelse.sub-bb54d45b-3534-4583-9ad3-4d24960a7a5a': 'Skade / hærverk',
    'kategoribeskrivelse.body-bb54d45b-3534-4583-9ad3-4d24960a7a5a':
      'Om det er åpne elektriske kabler/koblinger, vennligst ring vaktsentralen umiddelbart på tlf. 23482100.\n\nHusk at du må betale selv om det er feil på utstyret.',

    // Betalingsautomater - Tar ikke kort
    'kategoribeskrivelse.header-1990e04c-a194-4c04-b920-0c22ab921c2f': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-1990e04c-a194-4c04-b920-0c22ab921c2f': 'Betalingsautomater',
    'kategoribeskrivelse.sub-1990e04c-a194-4c04-b920-0c22ab921c2f': 'Tar ikke kort',
    'kategoribeskrivelse.body-1990e04c-a194-4c04-b920-0c22ab921c2f':
      'Har du forsøkt flere kort? På denne automaten kan du også betale med mynt.\n\nHusk at du må betale selv om det er feil på utstyret.',

    // Betalingsautomater - Tar ikke mynt
    'kategoribeskrivelse.header-62d2d550-beee-4818-bd9b-5c8d24f95830': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-62d2d550-beee-4818-bd9b-5c8d24f95830': 'Betalingsautomater',
    'kategoribeskrivelse.sub-62d2d550-beee-4818-bd9b-5c8d24f95830': 'Tar ikke mynt',
    'kategoribeskrivelse.body-62d2d550-beee-4818-bd9b-5c8d24f95830':
      'På denne automaten kan du også betale med kort.\n\nHusk at du må betale selv om det er feil på utstyret.',

    // Betalingsautomater - Kvittering skrives ikke ut
    'kategoribeskrivelse.header-29382bdf-971e-41c0-91f6-b867283820e0': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-29382bdf-971e-41c0-91f6-b867283820e0': 'Betalingsautomater',
    'kategoribeskrivelse.sub-29382bdf-971e-41c0-91f6-b867283820e0': 'Kvittering skrives ikke ut',
    'kategoribeskrivelse.body-29382bdf-971e-41c0-91f6-b867283820e0':
      'Oslo kommune har billettløs parkering. Du trenger derfor ikke legge billett i bilen. Ønsker du kvittering, send en e-post til postmottak@bym.oslo.kommune.no',

    // Betalingsautomater - Ute av drift
    'kategoribeskrivelse.header-2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce': 'Betalingsautomater',
    'kategoribeskrivelse.sub-2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce': 'Ute av drift',
    'kategoribeskrivelse.body-2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce':
      'Du kan betale via app.\n\nHusk at du må betale selv om det er feil på utstyret.',

    // Betalingsautomater - Display / kvittering viser feil
    'kategoribeskrivelse.header-179a7593-0d5a-4d11-b6bf-682578a5e664': 'Betalingsautomater',
    'kategoribeskrivelse.img-alt-179a7593-0d5a-4d11-b6bf-682578a5e664': 'Betalingsautomater',
    'kategoribeskrivelse.sub-179a7593-0d5a-4d11-b6bf-682578a5e664': 'Display / kvittering viser feil',
    'kategoribeskrivelse.body-179a7593-0d5a-4d11-b6bf-682578a5e664':
      'Informasjon om priser og ulike takstsoner finner du på www.oslo.kommune.no. Har du flere spørsmål, send e-post til postmottak@bym.oslo.kommune.no.\n\nHusk at du må betale selv om det er feil på utstyret.',

    // Rusken - Vårrusken - Søppel som er ryddet
    'kategoribeskrivelse.header-9c578bbb-f0d8-42a4-a161-9c0be6a07db5': 'Rusken',
    'kategoribeskrivelse.img-alt-9c578bbb-f0d8-42a4-a161-9c0be6a07db5': 'Rusken',
    'kategoribeskrivelse.sub-9c578bbb-f0d8-42a4-a161-9c0be6a07db5': 'Vårrusken - Søppel som er ryddet',
    'kategoribeskrivelse.body-9c578bbb-f0d8-42a4-a161-9c0be6a07db5':
      '• Frivillige som har bidratt til å rydde søppel under Vårrusken kan melde inn hvor søppelet skal hentes. Mer informasjon og påmelding til Vårrusken: <vaarrusken>https://rusken.no/varrusken</vaarrusken>.\n• Avfallssekkene skal være gjennomsiktige og kun inneholde restavfall - ikke hageavfall, grus eller farlig avfall.\n• Knyt igjen sekkene med dobbelt knute. Avfallssekker må plasseres på offentlig grunn, og skal ikke stå i veien for gående, syklende eller kjørende.\n• Marker i kartet hvor søpla er plassert, ta bilde av stedet og søpla som skal hentes, og legg ved i meldingen.\n• Oppgi antall sekker i beskrivelsen, og de siste fire siffer i referansenummeret som du mottok fra Vårrusken.\n• Før å følge saken anbefales det å registrere seg som bruker i <bymelding>https://bymelding.no</bymelding>\n\nDette er en tjeneste for frivillige som har ryddet forsøpling utendørs i sitt nærområde. Det er ikke en tjeneste for å få hentet møbler, farlig avfall, elektronikk eller hageavfall.\n\nSøk informasjon hos Renovasjons- og gjenvinningsetaten som har egen ordning for farlig avfall og hageavfall:\n<farligAvfall>Henting av farlig avfall</farligAvfall>\n<avfallshenting>Henting av hageavfall</avfallshenting>',

    // Button-texts
    'kategoribeskrivelse.continue': 'Fortsett til kart',
    'kategoribeskrivelse.cancel': 'Velg en annen kategori',
    'kategoribeskrivelse.read-more': 'Les mer her',
    '': '',

    // Hull i veien - Sykkelfelt
    'kategoribeskrivelse.header-d39c1c20-0fbf-446e-8b65-dedd3e0106d0': 'Sykkelfelt',
    'kategoribeskrivelse.sub-d39c1c20-0fbf-446e-8b65-dedd3e0106d0': 'Hull i veien',
    'kategoribeskrivelse.body-d39c1c20-0fbf-446e-8b65-dedd3e0106d0':
      'Legg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',

    // Hull i veien - Tur-, gang- og sykkelvei
    'kategoribeskrivelse.header-ece8ee84-16f5-43d5-87d4-bc6a22ff307d': 'Tur-, gang- og sykkelvei',
    'kategoribeskrivelse.sub-ece8ee84-16f5-43d5-87d4-bc6a22ff307d': 'Hull i veien',
    'kategoribeskrivelse.body-ece8ee84-16f5-43d5-87d4-bc6a22ff307d':
      'Legg gjerne ved bilder i meldingen, slik at vi raskt og enkelt kan ta en vurdering av saken.',

    // Brøyting - Tur-, gang og sykkelvei
    'kategoribeskrivelse.header-4a116561-9f9f-4de0-bae5-a5ab65efbab9': ' Tur-, gang og sykkelvei',
    'kategoribeskrivelse.sub-4a116561-9f9f-4de0-bae5-a5ab65efbab9': 'Brøyting/strøing',
    'kategoribeskrivelse.body-4a116561-9f9f-4de0-bae5-a5ab65efbab9':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Brøyting - Fortau
    'kategoribeskrivelse.header-abd43c6c-1e82-440e-b83d-b68d8c3745e7': ' Fortau',
    'kategoribeskrivelse.sub-abd43c6c-1e82-440e-b83d-b68d8c3745e7': 'Brøyting/strøing',
    'kategoribeskrivelse.body-abd43c6c-1e82-440e-b83d-b68d8c3745e7':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Brøyting - Holdeplass
    'kategoribeskrivelse.header-1b9d6f33-547d-4831-84bc-1d086d23ae24': ' Holdeplass',
    'kategoribeskrivelse.sub-1b9d6f33-547d-4831-84bc-1d086d23ae24': 'Brøyting/strøing',
    'kategoribeskrivelse.body-1b9d6f33-547d-4831-84bc-1d086d23ae24':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Brøyting - P-plass
    'kategoribeskrivelse.header-fed9deab-ed4a-4302-afd5-31318d9223fc': ' P-plass',
    'kategoribeskrivelse.sub-fed9deab-ed4a-4302-afd5-31318d9223fc': 'Brøyting/strøing',
    'kategoribeskrivelse.body-fed9deab-ed4a-4302-afd5-31318d9223fc':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Brøyting - Sykkelfelt
    'kategoribeskrivelse.header-710ca49a-9ac9-4dc5-85a9-74e23182b804': ' Sykkelfelt',
    'kategoribeskrivelse.sub-710ca49a-9ac9-4dc5-85a9-74e23182b804': 'Brøyting/strøing',
    'kategoribeskrivelse.body-710ca49a-9ac9-4dc5-85a9-74e23182b804':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Brøyting - Veibane
    'kategoribeskrivelse.header-ae3cc907-039e-4899-8dbf-c54c6fda8f98': ' Veibane',
    'kategoribeskrivelse.sub-ae3cc907-039e-4899-8dbf-c54c6fda8f98': 'Brøyting/strøing',
    'kategoribeskrivelse.body-ae3cc907-039e-4899-8dbf-c54c6fda8f98':
      '<b>Ikke meld fra om brøytebehov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - Tur-, gang og sykkelvei
    'kategoribeskrivelse.header-2b23ddbd-a9d5-47b1-a5d4-99ae29c87e4c': ' Tur-, gang og sykkelvei',
    'kategoribeskrivelse.sub-2b23ddbd-a9d5-47b1-a5d4-99ae29c87e4c': 'Brøyting/strøing',
    'kategoribeskrivelse.body-2b23ddbd-a9d5-47b1-a5d4-99ae29c87e4c':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - Fortau
    'kategoribeskrivelse.header-3ac81d7a-0340-4484-b311-8cdfa1ab2ede': ' Fortau',
    'kategoribeskrivelse.sub-3ac81d7a-0340-4484-b311-8cdfa1ab2ede': 'Brøyting/strøing',
    'kategoribeskrivelse.body-3ac81d7a-0340-4484-b311-8cdfa1ab2ede':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - Holdeplass
    'kategoribeskrivelse.header-1c179001-8b7d-4408-9370-6a6e65b06fab': ' Holdeplass',
    'kategoribeskrivelse.sub-1c179001-8b7d-4408-9370-6a6e65b06fab': 'Brøyting/strøing',
    'kategoribeskrivelse.body-1c179001-8b7d-4408-9370-6a6e65b06fab':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - P-plass
    'kategoribeskrivelse.header-50818a2f-2b0d-4771-8d4f-8049c04674af': ' P-plass',
    'kategoribeskrivelse.sub-50818a2f-2b0d-4771-8d4f-8049c04674af': 'Brøyting/strøing',
    'kategoribeskrivelse.body-50818a2f-2b0d-4771-8d4f-8049c04674af':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - Sykkelfelt
    'kategoribeskrivelse.header-1d1c23f7-689c-43c6-ab14-3a9882fdd46d': ' Sykkelfelt',
    'kategoribeskrivelse.sub-1d1c23f7-689c-43c6-ab14-3a9882fdd46d': 'Brøyting/strøing',
    'kategoribeskrivelse.body-1d1c23f7-689c-43c6-ab14-3a9882fdd46d':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',

    // Strøing - Veibane
    'kategoribeskrivelse.header-382599d1-b71c-44a2-b6d6-f72f67f28798': ' Veibane',
    'kategoribeskrivelse.sub-382599d1-b71c-44a2-b6d6-f72f67f28798': 'Brøyting/strøing',
    'kategoribeskrivelse.body-382599d1-b71c-44a2-b6d6-f72f67f28798':
      '<b>Ikke meld fra om strø-behov <i>mens</i> det snør!</b> Alt mannskap er ute i arbeid, og meldingen blir ikke sett. Vent minst 6 timer fra det <b>slutter</b> å snø, til du melder fra om feil.\n\nMeldingen tas med i helhetsvurderingen av forholdene og hva som må prioriteres i området.',
  },
  en: {
    'kategoribeskrivelse.header-710da443-87f6-4b0b-aaf1-d4616fc637d5': 'Mørkt område minst 4 stk',
    'kategoribeskrivelse.sub-710da443-87f6-4b0b-aaf1-d4616fc637d5': 'Gatelys',
    'kategoribeskrivelse.body-710da443-87f6-4b0b-aaf1-d4616fc637d5':
      'Meld fra om mørke lamper, slik at vi får kartlagt og registrert dem. \n\nBeskjed går direkte til de som utbedrer jobben, og du får tilbakemelding når jobben er gjort. Merk at det kan ta opp til 28 dager å få feilen utbedret.\n\nGatelys som BYM er ansvarlig for er plottet inn i kartet.',
    'kategoribeskrivelse.header-6e6078e5-f479-4800-90d3-08b465b95885': 'Mørk lampe',
    'kategoribeskrivelse.sub-6e6078e5-f479-4800-90d3-08b465b95885': 'Gatelys',
    'kategoribeskrivelse.body-6e6078e5-f479-4800-90d3-08b465b95885':
      'Er det feil på 4 lamper eller flere,  marker området i kartet og meld inn mørkt område.\n\nNår et større område er mørkt er årsaken ofte kabelbrudd, og utbedring kan ta noe lenger tid enn ved en enkelt mørk lampe. I hovedsak vil utbedring være påbegynt etter 1 virkedag.',
    'kategoribeskrivelse.continue': 'Continue to map',
    'kategoribeskrivelse.cancel': 'Select another category',
    'kategoribeskrivelse.read-more': 'Read more',
    '': '',
  },
};

export default kategoribeskrivelser;
